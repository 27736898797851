<template>
    <div class="product-selection">
        <div class="picture d-none d-lg-block">
            <img :src="product.picture" 
                :title="getProductTitleName()"
                :alt="getProductTitleName()"
                @error="helper.setNoImg($event, 'product')" />
        </div>
        <div class="info">
            <img :src="product.brandLogo" 
                :title="product.brand"
                :alt="product.brand"
                class="brand"
                @error="helper.setNoImg($event, 'brand')" />
            <div class="name mt-2">
                <i>{{ product.brand }} {{ product.name }}</i>
                <span>ขนาดยาง {{ product.size }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import Helper from '@/services/helper';

export default {
    data() {
		return {
            helper: Helper
		};
	},
	props: {
        product: Object
	},
	methods: {
        getProductTitleName() {
            return [ this.product.brand, this.product.name, this.product.size ].join(' ');
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.product-selection {
    margin-bottom: 20px;
    display: flex;

    .picture {
        position: relative;
        overflow: hidden;
        width: 195px;
        height: 80px;

        img {
            position: absolute;
            top: -20%;
            width: 100%;
        }
    }

    .info {
        flex: 1;
        display: flex;
        align-items: center;

        @media only screen and (max-width: $screenExtraSmall) {
            display: block;
            text-align: center;
        }

        .brand {
            height: 36px;
            border: 1px solid #bbb;
        }

        .name {
            flex: 1;
            padding-left: 30px;

            @media only screen and (max-width: $screenExtraSmall) {
                padding-left: 0;
            }

            i {
                display: block;
                font-size: 18px;
                line-height: 18px;
                font-weight: bold;
                letter-spacing: -0.5px;
                color: #555;

                @media only screen and (max-width: $screenSmall) {
                    font-size: 16px;
                    line-height: 18px;
                }
            }

            span {
                display: block;
                font-size: 14px;
                color: #888;

                @media only screen and (max-width: $screenSmall) {
                    font-size: 12px;
                }
            }
        }
    }
}
</style>