<template>
    <div class="delivery" v-if="isFormReady">
        <div class="wrapper">
            <div class="decoration"></div>
            <div class="input-field">
                <label><b-icon icon="truck" class="icon"></b-icon> กรอกพื้นที่เพื่อตรวจสอบร้านค้าที่รับจัดส่ง (ตำบล/อำเภอ/จังหวัด/ไปรษณีย์)</label>
                <address-auto-complete
                    ref="thaiFullAddressData"
                    placeholder="พิมพ์อย่างใดอย่างหนึ่ง ตำบล/อำเภอ/จังหวัด/ไปรษณีย์"
                    :listData="mixinsThaiAddressList"
                    v-model="deliveryArea"
                    @hit="mixinsThaiFullAddressSetThaiAddressValue($event)"
                    @input="mixinsThaiFullAddressSetAutoComplete($event)"
                    @blur="mixinsThaiFullAddressCheckValidInputAddress($event)" />
            </div>

            <div class="action">
                <button class="btn btn-danger" @click="submitArea()">ตรวจสอบ</button>
            </div>
        </div>
    </div>
</template>

<script>
import Helper from '@/services/helper';
import ThaiAddress from '@/mixins/thaiAddress';

export default {
    mixins: [ ThaiAddress ],
    data() {
		return {
            isFormReady: false,
            deliveryArea: null
		};
	},
    mounted() {
        this.checkAddressData();
    },
	methods: {
        hide() {
            this.$emit('close');
        },
        checkAddressData() {
            if (localStorage.deliveryArea) {
                this.deliveryArea = decodeURIComponent(window.atob(localStorage.deliveryArea));
            }

            this.isFormReady = true;
        },
        async submitArea() {
            if (this.deliveryArea) {
                localStorage.deliveryArea = window.btoa(encodeURIComponent(this.deliveryArea));

                this.$emit('submitArea', this.deliveryArea);
            } else {
                this.$swal(Helper.warningAlert('กรุณาเลือกพื้นที่จัดส่ง', 'กรอกพื้นที่ (ตำบล/อำเภอ/จังหวัด/ไปรษณีย์ อย่างใดอย่างหนึ่ง) เพื่อค้นหาร้านค้าที่มีบริการจัดส่ง'));
            }
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.delivery {
    border: 1px dotted #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    height: 300px;
    @include borderRadius(5px);

    .wrapper {
        position: relative;
        max-width: 500px;
        width: 100%;
        padding: 25px 25px 20px;
        border: 1px solid #ccc;
        border-top: none;
        @include boxShadow(0 0 10px rgba(100, 100, 100, 0.1));
        
        .decoration {
            @extend .deliveryDecoration;
        }
    }

    .input-field {
        margin-bottom: 12px;

        label {
            color: #000;
            margin-bottom: 5px;

            .icon {
                margin-right: 5px;
                opacity: 0.7;
            }
        }
    }

    .action {
        text-align: center;

        .btn {
            padding: 5px 12px;
            font-size: 13px;
        }
    }
}
</style>