<template>
    <div class="box" :class="{ 'sponsor': product.isSponsor }">
        <router-link
            :to="getProductLink()"
            :title="getAltTitleName()"
            class="title">
            <img :src="product.brandLogo" :alt="'ยางรถยนต์ ' + product.brand" :title="'ยางรถยนต์ ' + product.brand" class="brand" @error="helper.setNoImg($event, 'brand')" />
            <component :is="useH2 ? 'h2' : 'div'" class="name">
                <span>ยางรถยนต์ <strong>{{ product.brand }}</strong></span>
                <span>รุ่น <strong>{{ product.name }}</strong></span>
                <span>ขนาด <strong>{{ product.size }}</strong></span>
                <span v-if="product.year">ปีผลิต <strong>{{ product.year }}</strong></span>
            </component>
        </router-link>

        <button class="btn-compare"
            title="เปรียบเทียบ" 
            @click="setCompare()"
            v-if="isShowCompare">
            <img src="@/assets/icon-compare.png" alt="เปรียบเทียบ" title="เปรียบเทียบ" />
        </button>

        <product-share-link :link="getShareStoreLink()" class="share-link" v-if="type === constants.ITEM_BOX_TYPE.PURCHASE"></product-share-link>
        <product-share-link :link="getShareNetworkLink()" class="share-link" v-if="type === constants.ITEM_BOX_TYPE.NETWORK"></product-share-link>

        <router-link
            :to="getProductLink()"
            :title="getAltTitleName()"
            class="info">
            <div class="content-picture-with-overlay picture">
                <div class="out-of-stock" v-if="type === constants.ITEM_BOX_TYPE.PURCHASE && !product.hasStock && !product.hasStockDelivery">
                    <span>หมด</span>
                </div>
                <div class="sponsor-overlay" v-if="product.isSponsor">โฆษณา</div>
                <img :src="product.picture" :alt="getAltTitleName()" :title="getAltTitleName()" @error="helper.setNoImg($event, 'product')" />
                <template v-if="type === constants.ITEM_BOX_TYPE.NETWORK || type === constants.ITEM_BOX_TYPE.PURCHASE">
                    <gadget-overlay-promotion
                        :hasFreeDelivery="product.overlay.hasFreeDelivery"
                        :canInstallment="product.overlay.canInstallment"
                        :hasFreeGift="product.overlay.hasFreeGift"
                        :hasTurnTire="product.overlay.hasTurnTire"
                        :discount="product.overlay.discount"
                        :services="product.overlay.services"
                        :promotion="product.overlay.promotion">
                    </gadget-overlay-promotion>
                </template>
            </div>
        </router-link>

        <div>
            <div class="icons">
                <div class="country">
                    <img :src="product.country.flag"
                        :alt="product.country.text"
                        :title="product.country.text"
                        @error="helper.setNoImg($event, 'country')"
                        v-if="product.country.flag" />
                    <img src="/img/no-country.jpg"
                        alt="No Data"
                        title="No Data"
                        v-else />
                </div>
                <div class="item">
                    <img :src="product.tsiPicture"
                        :alt="'มอก. ' + product.name"
                        :title="'มอก. ' + product.name"
                        @error="helper.setNoImg($event, 'tsi')"
                        v-if="product.tsiPicture" />
                    <img src="/img/no-tsi-data.jpg"
                        alt="No Data"
                        title="No Data"
                        v-else />
                </div>
                <div class="item">
                    <img :src="product.performance.icon"
                        :alt="'Performance ' + product.name"
                        :title="'Performance ' + product.name"
                        @error="helper.setNoImg($event, 'eco')"
                        v-if="product.performance.icon" />
                    <img src="/img/no-eco-data.jpg"
                        alt="No Data"
                        title="No Data"
                        v-else />
                </div>
            </div>

            <div class="spec">
                <div class="item">
                    <label>ความเร็วสูงสุด {{ product.maxSpeedUnit }}</label>
                    <span>: {{ product.maxSpeedKm }}</span>
                </div>
                <div class="item">
                    <label>รับน้ำหนักต่อล้อ {{ product.weightTireIndex }}</label>
                    <span>: {{ product.weightTireKg }}</span>
                </div>
            </div>

            <div class="attributes">
                <div class="wrapper">
                    <img :src="item.icon" :alt="item.title" :title="item.title" v-for="(item, index) in product.attributes" :key="index" />
                </div>
            </div>

            <!-- Display Price for Store -->
            <div class="price-multiple" v-if="type === constants.ITEM_BOX_TYPE.PURCHASE">
                <div class="price" v-if="product.price">
                    <label>ราคาต่อเส้น (ติดตั้งที่ร้าน)</label>
                    <span :class="{ 'cut': product.discountPrice }">฿{{ product.price }}</span>
                    <span v-if="product.discountPrice">฿{{ product.discountPrice }}</span>
                </div>
                <div class="price" v-if="product.priceDelivery">
                    <label>ราคาต่อเส้น (จัดส่ง)</label>
                    <span :class="{ 'cut': product.discountPriceDelivery }">฿{{ product.priceDelivery }}</span>
                    <span v-if="product.discountPriceDelivery">฿{{ product.discountPriceDelivery }}</span>
                </div>
            </div>

            <!-- Display Price for General -->
            <div class="price-data" v-if="product.priceRange">
                <div class="price range">
                    <label>ราคาต่อเส้น</label>
                    <span>{{ product.priceRange }}</span>
                </div>
                <router-link :to="getProductLink()" :title="product.size + ' ' + product.brand + ' ' + product.name" class="btn-view-store">ดูสินค้า <b-icon icon="chevron-right" class="icon"></b-icon></router-link>
            </div>

            <div class="price-data" v-if="type !== constants.ITEM_BOX_TYPE.PURCHASE && !product.priceRange">
                <div class="price">
                    <span class="grey">ไม่มีจำหน่าย</span>
                </div>
            </div>
        </div>

        <div class="text-center mt-2" v-if="type === constants.ITEM_BOX_TYPE.GENERAL || type === constants.ITEM_BOX_TYPE.NETWORK">
            <div class="action-wrapper">
                <button
                    :title="product.size + ' ' + product.brand + ' ' + product.name"
                    class="btn btn-warning btn-action"
                    :class="{ 'not-allow': !product.hasDelivery }"
                    v-if="product.hasInstallation || product.hasDelivery"
                    @click="toggleStoreList(product.hasDelivery, true)">
                    <b-icon icon="truck" class="icon"></b-icon> จัดส่ง
                </button>
                <button
                    :title="product.size + ' ' + product.brand + ' ' + product.name"
                    class="btn btn-red-light btn-action"
                    :class="{ 'not-allow': !product.hasInstallation }"
                    v-if="product.hasInstallation || product.hasDelivery"
                    @click="toggleStoreList(product.hasInstallation, false)">
                    <b-icon icon="tools" class="icon"></b-icon> ติดตั้ง
                </button>
                <a :href="helper.getFacebookLink()"
                    target="_blank"
                    v-if="!product.hasInstallation && !product.hasDelivery"
                    class="btn btn-grey btn-action">
                    <b-icon icon="messenger" class="icon"></b-icon> สอบถาม
                </a>
            </div>
        </div>

        <div class="text-center mt-2" v-if="type === constants.ITEM_BOX_TYPE.PURCHASE">
            <div class="action-wrapper">
                <button
                    v-if="product.hasStock || product.hasStockDelivery"
                    :title="product.size + ' ' + product.brand + ' ' + product.name"
                    class="btn btn-warning btn-action"
                    :class="{ 'not-allow': !product.hasDelivery || !product.hasStockDelivery }"
                    @click="purchaseDelivery(product.hasDelivery && product.hasStockDelivery)">
                    <b-icon icon="truck" class="icon"></b-icon> จัดส่ง
                </button>
                <button
                    v-if="product.hasStock || product.hasStockDelivery"
                    :title="product.size + ' ' + product.brand + ' ' + product.name"
                    class="btn btn-red-light btn-action"
                    :class="{ 'not-allow': !product.hasInstallation || !product.hasStock }"
                    @click="purchaseInstall(product.hasInstallation && product.hasStock)">
                    <b-icon icon="tools" class="icon"></b-icon> ติดตั้ง
                </button>
                <a :href="helper.getFacebookLink()"
                    target="_blank"
                    v-if="!product.hasStock && !product.hasStockDelivery"
                    class="btn btn-grey btn-action">
                    <b-icon icon="messenger" class="icon"></b-icon> สอบถาม
                </a>
            </div>
        </div>

        <modal-store-list
            @close="closeStoreList()"
            :isDisplay="isOpenStoreList"
            :isDelivery="isDelivery"
            :initFilters="initStoreFilter"
            :product="selectedProduct"
            :trackingReferrer="trackingReferrer"
            :networkId="networkId">
        </modal-store-list>
    </div>
</template>

<script>
import RouterPath from '@/router/path';
import MixinModalStoreList from '@/mixins/modalStoreList';
import Helper from '@/services/helper';
import Constants from '@/variables/constants';
import OrderService from '@/services/orderService';
import ProductShareLink from '@/components/product/ShareLink';
import GadgetOverlayPromotion from '@/components/gadget/OverlayPromotion';
import ModalStoreList from '@/components/store/ModalStoreList';

export default {
    mixins: [ MixinModalStoreList ],
    components: {
        ProductShareLink,
        GadgetOverlayPromotion,
        ModalStoreList
    },
    data() {
        return {
            routerPath: RouterPath,
            helper: Helper,
            constants: Constants
        };
    },
    props: {
        product: {
            type: [ Object, String ],
            required: true
        },
        store: {
            type: Object,
            required: false
        },
        networkId: {
            type: String,
            required: false,
        },
        type: {
            type: String,
            required: false,
            default: 'general'
        },
        isShowCompare: {
            type: Boolean,
            required: false,
            default: false
        },
        useH2: {
            type: Boolean,
            required: false,
            default: true
        },
        trackingReferrer: {
            type: String,
            required: false,
            default: 'unknown'
        },
        initStoreFilter: {
            type: Array,
            requred: false
        }
    },
    methods: {
        getAltTitleName() {
            return [ 'ยางรถยนต์', this.product.brand, 'รุ่น', this.product.name, this.product.size ].join(' ');
        },
        toggleStoreList(isAllowAction, isDelivery) {
            if (isAllowAction) {
                this.openStoreList({ product: this.product, isDelivery: isDelivery });
            }
        },
        async purchaseInstall(isAllowAction) {
            if (isAllowAction) {
                const vueObject = {
                    modalLoader: this.$modalLoader,
                    router: this.$router,
                    gtag: this.$gtag,
                    gtm: this.$gtm,
                    root: this.$root,
                    swal: this.$swal
                };
                const param = {
                    sellerProductId: this.product.sellerProductId,
                    storeId: Helper.splitDotParam(this.store.param),
                    orderType: Constants.ORDER_TYPE.SERVICE,
                    referer: this.trackingReferrer
                };
                const gtmData = {
                    product: this.product,
                    type: 'install',
                    sellerProductId: this.product.sellerProductId,
                    storeId: Helper.splitDotParam(this.store.param),
                    price: this.product.price
                };

                OrderService.toggleAddCartEvent(vueObject, param, gtmData);
            }
        },
        purchaseDelivery(isAllowAction) {
            if (isAllowAction) {
                this.$emit('purchaseDelivery', this.product);
            }
        },
        getProductLink() {
            let link = Helper.replaceRouteParam(RouterPath.TIRE_DETAIL, ':id', this.product.param);

            // Send as general but contain network id. Use for related article
            if (this.networkId) {
                link = this.getShareNetworkLink();
            }

            switch (this.type) {
                case Constants.ITEM_BOX_TYPE.PURCHASE:
                    link = this.getShareStoreLink();
                    break;

                case Constants.ITEM_BOX_TYPE.NETWORK:
                    link = this.getShareNetworkLink();
                    break;
            }

            return link;
        },
        getShareStoreLink() {
            return Helper.replaceRouteParam(RouterPath.STORE_DETAIL, ':id', this.store.param) + '/' + this.product.sellerProductId;
        },
        getShareNetworkLink() {
            return Helper.replaceRouteParam(RouterPath.NETWORK_DETAIL, ':key', this.networkId) + '/' + this.product.id;
        },
        setCompare() {
            this.$emit('setCompare', this.product);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.box {
    position: relative;
    background: #fff;
    border-top: 2px solid #ffd800;
    padding: 5px;
    display: block;
    color: #000;
    overflow: hidden;

    &.sponsor {
        border: 2px solid #ababab;
    }

    a {
        display: block;
        color: #000;
        margin: auto;
    }

    .title {
        margin-bottom: 7px;
        color: #000;

        &:hover {
            text-decoration: underline;
        }

        .name {
            margin: 0;

            span {
                font-size: 13px;
                line-height: 15px;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;

                @media only screen and (max-width: $screenExtraSmall) {
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }

        .brand {
            display: block;
            width: 60%;
            max-width: 120px;
            border: 1px solid #ddd;
            margin-bottom: 5px;
        }
    }

    .share-link {
        position: absolute;
        top: 0;
        right: 5px;
        z-index: 1;
    }

    .btn-compare {
        position: absolute;
        top: 4px;
        right: 0;
        z-index: 0;
        border: none;
        background: #fff;

        img {
            width: 37px;
        }
    }
    
    a {
        display: block;
        margin: auto;
    }

    .picture {
        position: relative;
        width: 100%;
        padding-top: 65%;
        overflow: hidden;
        border: 1px solid #ddd;

        .sponsor-overlay {
            position: absolute;
            z-index: 1;
            bottom: 0;
            right: 0;
            padding: 4px 8px;
            font-size: 11px;
            color: #fff;
            background: #ababab;
        }

        img {
            display: block;
            width: 100%;
            position: absolute;
            top: -20px;
            left: 0;
            right: 0;
        }

        .out-of-stock {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                display: flex;
                width: 100px;
                height: 100px;
                justify-content: center;
                align-items: center;
                color: #fff;
                background: rgba(250, 0, 0, 0.3);
                @include borderRadius(1000px);
            }
        }
    }

    .info {
        margin-bottom: 5px;
    }

    .icons {
        display: flex;
        align-items: center;
        overflow: hidden;

        img {
            height: 29px;

            @media only screen and (max-width: $screenLarge) {
                height: 27px;
            }

            @media only screen and (max-width: $screenMedium) {
                height: 35px;
            }

            @media only screen and (max-width: $screenExtraSmall) {
                height: 28px;
            }

            @media only screen and (max-width: 430px) {
                height: 24px;
            }
        }

        .item {
            padding-left: 2px;
            display: block;
        }
    }

    .spec {
        font-size: 12px;
        line-height: 14px;
        margin: 5px 0;

        @media only screen and (max-width: $screenLarge) {
            font-size: 11px;
            line-height: 13px;
        }

        .item {
            display: flex;
            flex-wrap: wrap;
            margin: 3px 0;

            label {
                margin-bottom: 0;
                display: block;
                white-space: nowrap;
            }

            span {
                display: block;
                white-space: nowrap;
            }
        }
    }

    .desc {
        font-size: 12px;
        line-height: 16px;
        color: #333;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;

        @media only screen and (max-width: $screenLarge) {
            font-size: 11px;
            line-height: 15px;
        }
    }

    .attributes {
        position: relative;
        overflow: hidden;
        height: 34px;

        .wrapper {
            position: absolute;
            display: flex;
            align-items: center;

            img {
                display: block;
                height: 34px;
                margin-right: 3px;
                border: 1px solid #999;
                background: #f2f2f2;
            }
        }
    }

    .price-multiple {
        display: flex;
        justify-content: space-between;

        .price {
            margin-top: 5px;

            & + .price {
                text-align: right;
            }

            label {
                margin-bottom: 4px;
                font-size: 11px;
                color: #333;
                display: block;
            }

            span {
                display: block;
                font-size: 15px;
                font-weight: bold;
                font-family: Verdana;
                color: #cc0000;

                @media only screen and (max-width: $screenExtraSmall) {
                    font-size: 13px;
                }

                &.cut {
                    font-size: 11px;
                    font-weight: normal;
                    line-height: 13px;
                    color: #aaa;
                    text-decoration: line-through;
                }
            }
        }
    }

    .price-data {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding-top: 10px;

        @media only screen and (max-width: $screenExtraSmall) {
            padding-top: 5px;
        }

        .price {
            line-height: normal;

            &.range {
                label {
                    font-size: 11px;
                    line-height: 13px;
                    color: #666;
                    display: block;
                }
            }

            span {
                font-size: 13px;
                color: #cc0000;
                white-space: nowrap;

                @media only screen and (max-width: $screenExtraSmall) {
                    font-size: 12px;
                }

                &.grey {
                    color: #999;
                }
            }
        }

        .btn-view-store {
            display: block;
            margin: 0;
            padding: 2px 8px 2px 10px;
            font-size: 12px;
            background: #fff02a;
            color: #000;
            @include borderRadius(3px);

            @media only screen and (max-width: $screenExtraSmall) {
                padding: 2px 4px 2px 6px;
                font-size: 11px;
            }

            .icon {
                font-size: 11px;

                @media only screen and (max-width: $screenExtraSmall) {
                    font-size: 10px;
                }
            }
        }
    }

    .action-wrapper {
        display: flex;
        
        .btn-action {
            position: relative;
            width: 50%;
            height: 34px;
            margin: 3px 2px 0;
            font-size: 14px;
            line-height: 14px;
            padding: 0;
            padding-left: 10%;
            display: flex;
            align-items: center;
            justify-content: center;

            &.not-allow {
                cursor: not-allowed;
                background: #ccc;
                border: 1px solid #ccc;
                color: #666;

                &:hover {
                    opacity: 1;
                }
            }

            .icon {
                position: absolute;
                left: 8px;
                font-size: 15px;
                line-height: 15px;

                @media only screen and (max-width: $screenExtraSmall) {
                    left: 5px;
                }
            }
        }
    }
}
</style>