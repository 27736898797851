<template>
    <div>
        <div class="benefit">
            <div class="item" v-if="canInstallment">ผ่อน 0%</div>
            <div class="item" v-if="hasFreeGift">มีของแถม</div>
            <div class="item" v-if="hasTurnTire">โปรเทิร์นยาง</div>
        </div>
        
        <div class="services">
            <div class="item" v-for="(item, index) in services" :key="index">
                ฟรี {{ item }}
            </div>
        </div>
        <div class="promotion">
            <div class="item" v-if="hasFreeDelivery">ส่งฟรี!</div>
            <div class="item" v-if="discount">ลด<span>{{ discount }}</span></div>
            <div class="item" v-if="promotion">{{ promotion }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        hasFreeDelivery: Boolean,
        canInstallment: Boolean,
        hasFreeGift: Boolean,
        hasTurnTire: Boolean,
        discount: String,
        services: [ Array, Object ],
        promotion: String
    }
}
</script>