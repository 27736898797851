<template>
    <transition name="modal" v-if="isActive">
        <div class="modal-mask modal-store-list">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <perfect-scrollbar>
                            <button class="btn-close" @click="hide()"><b-icon icon="x"></b-icon></button>

                            <div class="white-box">
                                <div class="container">
                                    <store-product-selection :product="product"></store-product-selection>

                                    <gadget-step-purchase :step="2"></gadget-step-purchase>

                                    <template v-if="isDelivery && isSearchAreaMode">
                                        <store-delivery-checker
                                            class="mt-3"
                                            @submitArea="submitArea($event)">
                                        </store-delivery-checker>
                                    </template>
                                </div>
                            </div>

                            <div class="container">
                                <section class="content-container grey-box" v-show="isSearchReady && !isSearchAreaMode">
                                    <div class="filter">
                                        <div class="loading" v-if="!isFilterReady">
                                            <loader-content :isDisplay="true"></loader-content>
                                        </div>
                                        
                                        <div class="content-mobile-filter-modal" :class="{ 'active': isShowMobileFilter }" @click="toggleFilterMobile()" v-if="isFilterReady">
                                            <div class="box" @click.stop>
                                                <perfect-scrollbar>
                                                    <div class="filter-title">
                                                        <b-icon icon="sliders" class="icon"></b-icon> กรองผลการค้นหา <b-icon icon="x" @click="toggleFilterMobile()" class="close-icon"></b-icon>
                                                    </div>
                                                    <filter-search
                                                        v-if="filterOptions && tags"
                                                        ref="filterSearch"
                                                        :filterOptions="filterOptions"
                                                        :tagSelections="tags"
                                                        @filterResult="changeFilter($event)">
                                                    </filter-search>
                                                </perfect-scrollbar>
                                                <div class="operation-panel">
                                                    <div class="wrapper">
                                                        <button class="btn btn-reset btn-outline-secondary" @click="resetFilter()">รีเซ็ต</button>
                                                        <button class="btn btn-main d-xl-none" @click="toggleFilterMobile()">ดูร้านค้า {{ totalResult }} ร้าน</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content-body">
                                        <div class="area" v-if="isDelivery">
                                            <div class="decoration"></div>

                                            <label>พื้นที่จัดส่งสินค้า</label>
                                            <span>
                                                <b-icon icon="truck" class="icon"></b-icon>{{ deliveryArea }}
                                            </span>
                                            <button class="btn btn-danger" @click="backToSearchArea()">แก้ไขพื้นที่จัดส่ง</button>
                                        </div>

                                        <gadget-alert-geolocation :state="displayAlertGeolocation" v-if="displayAlertGeolocation > 0 && sortType === 'near'" class="mb-2"></gadget-alert-geolocation>

                                        <div class="store-search">
                                            <div class="content-mobile-filter">
                                                <button @click="toggleFilterMobile()"><b-icon icon="sliders" class="filter-icon"></b-icon> กรองผลการค้นหา</button>
                                            </div>

                                            <gadget-sort-bar :sortOption="sortOption" :defaultOption="sortOption[0].id" @changeSort="changeSort($event)" class="mb-3"></gadget-sort-bar>

                                            <div class="loading" v-if="!isStoreReady">
                                                <loader-content :isDisplay="true"></loader-content>
                                            </div>

                                            <template v-if="isStoreReady">
                                                <gadget-search-tag :tags="tags" @removeTag="removeTag($event)" class="mb-3" v-if="tags && tags.length > 0"></gadget-search-tag>

                                                <section class="store-grid">
                                                    <div class="store"
                                                        v-for="(item, index) in storeList"
                                                        :key="index">
                                                        <store-purchase 
                                                            :store="item"
                                                            :product="product"
                                                            :isDelivery="isDelivery"
                                                            :trackingReferrer="trackingReferrer">
                                                        </store-purchase>
                                                    </div>

                                                    <div class="no-data" v-if="storeList.length === 0">
                                                        <b-icon icon="box-seam"></b-icon>
                                                        <span v-if="isDelivery">ขออภัยครับ สินค้าที่เลือก ยังไม่มีบริการจัดส่งในพื้นที่ของท่าน</span>
                                                        <span v-else>ไม่พบร้านค้าที่กำลังค้นหา</span>
                                                    </div>
                                                </section>

                                                <paging
                                                    @page="changePage($event)"
                                                    :activePage="activePage"
                                                    :totalPage="totalPage"
                                                    class="mt-4 mb-3">
                                                </paging>
                                            </template>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </perfect-scrollbar>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import Helper from '@/services/helper';
import RouterPath from '@/router/path';
import ThaiAddress from '@/mixins/thaiAddress';
import MixinGeolocationContent from '@/mixins/geolocationContent';
import Constants from '@/variables/constants';
import ProductService from '@/services/productService';
import FilterSearch from '@/components/gadget/FilterSearch';
import StoreProductSelection from '@/components/store/ProductSelection';
import StoreDeliveryChecker from '@/components/store/StoreDeliveryChecker';
import StorePurchase from '@/components/store/StorePurchase';
import GadgetAlertGeolocation from '@/components/gadget/AlertGeolocation';
import GadgetSortBar from '@/components/gadget/SortBar';
import GadgetSearchTag from '@/components/gadget/SearchTag';
import GadgetStepPurchase from '@/components/gadget/StepPurchase';

export default {
    mixins: [ ThaiAddress, MixinGeolocationContent ],
    components: {
        FilterSearch,
        StoreProductSelection,
        StoreDeliveryChecker,
        StorePurchase,
        GadgetAlertGeolocation,
        GadgetSortBar,
        GadgetSearchTag,
        GadgetStepPurchase
    },
    data() {
		return {
            isActive: false,
            constants: Constants,
            routerPath: RouterPath,
            isShowMobileFilter: false,
            filter: null,
            filterOptions: null,
            isFilterReady: false,
            isSearchReady: false,
            isStoreReady: false,
            isSearchAreaMode: false,
            storeList: [],
            totalResult: 0,
            activePage: 1,
            totalPage: 0,
            tags: [],
            sortOption: [
                { id: 'near', name: 'ร้านที่ใกล้' },
                { id: 'priceAsc', name: 'ราคาน้อยไปมาก' },
                { id: 'priceDesc', name: 'ราคามากไปน้อย' }
                
            ],
            sortType: 'near',
            deliveryArea: null
		};
	},
	props: {
		isDisplay: Boolean,
        isDelivery: Boolean,
        initFilters: Array,
        product: Object,
        networkId: {
            type: String,
            required: false
        },
        trackingReferrer: {
            type: String,
            required: false
        }
	},
    watch: {
        async isDisplay(value) {
            this.isActive = value;
            this.$root.$emit('rootSetContentScrollable', value);

            if (value) {
                // Reset
                this.isSearchReady = false;
                this.isSearchAreaMode = false;
                this.storeList = [];
                this.totalResult = 0;
                this.activePage = 1;
                this.totalPage = 0;
                this.filter = null;

                if (!this.isDelivery) {
                    this.isSearchReady = true;

                    // Parallel async loading
                    await Promise.all([this.getStoreFilters(), this.checkGeolocation()]);
                    this.getStoreResult();
                } else {
                    this.isSearchAreaMode = true;
                }
            } else {
                this.$root.$emit('rootToggleFacebookChat', true);
            }
        }
    },
    destroyed() {
        this.$root.$emit('rootToggleFacebookChat', true);
        this.$root.$emit('rootSetContentScrollable', false);
    },
	methods: {
        hide() {
            this.$emit('close');
        },
        async submitArea(data) {
            this.isSearchAreaMode = false;
                
            if (!this.isSearchReady) {
                this.isSearchReady = true;
                
                await Promise.all([this.getStoreFilters(), this.checkGeolocation()]);
            }

            this.deliveryArea = data;
            this.getStoreResult();
        },
        backToSearchArea() {
            this.isSearchAreaMode = true;
        },
        async getStoreFilters() {
            this.isFilterReady = false;

            if (this.initFilters) {
                this.filterOptions = this.initFilters;
            } else {
                const param = {
                    id: this.getTireId(),
                    orderType: this.isDelivery ? Constants.ORDER_TYPE.DELIVERY : Constants.ORDER_TYPE.SERVICE,
                };
                const result = await ProductService.getSellerFilters(param);

                this.filterOptions = result.data.filter;
            }

            this.isFilterReady = true;
        },
		async getStoreResult() {
            this.isStoreReady = false;

			const param = await this.setupParamStore();
            const result = await ProductService.searchStore(param);

            this.storeList = result.data.resultList;
            this.tags = result.data.tags;
            this.totalPage = result.data.totalPage;
            this.totalResult = result.data.totalResult;
            this.isStoreReady = true;
        },
        getTireId() {
            let tireId = Helper.splitDotParam(this.$route.params.id);

            if (!tireId) {
                tireId = this.product.id;
            }

            return tireId;
        },
		async setupParamStore() {
			const position = this.geolocationState === 'granted' ? await new Promise((resolve, reject) => {
				navigator.geolocation.getCurrentPosition(resolve, reject);
			}) : null;

			const param = {
				page: this.activePage,
				tireId: this.getTireId(),
                networkId: this.networkId,
                deliverArea: this.getDeliveryData(),
                orderType: this.isDelivery ? Constants.ORDER_TYPE.DELIVERY : Constants.ORDER_TYPE.SERVICE,
				filter: this.filter,
				sort: this.sortType,
                event: 'addCart',
				latitude: position ? position.coords.latitude : null,
				longtitude: position ? position.coords.longitude : null,
			};

			return param;
		},
        getDeliveryData() {
            if (this.deliveryArea) {
                const deliveryData = this.deliveryArea.split(this.mixinsThaiAddressSeparator);

                return {
                    tumbon: deliveryData[this.mixinsThaiAddressDataIndex.tumbon],
                    amphur: deliveryData[this.mixinsThaiAddressDataIndex.amphur],
                    province: deliveryData[this.mixinsThaiAddressDataIndex.province],
                    postcode: deliveryData[this.mixinsThaiAddressDataIndex.postcode]
                }
            }
            
            return null;
        },
		changeFilter(data) {
			this.filter = data.filter;
			this.changePage(1);
		},
		changeSort(data) {
			this.sortType = data.value;

			if (this.sortType) {
				this.getStoreResult();
			}
		},
		changePage(page) {
			this.activePage = page;
			this.getStoreResult();
		},
		toggleFilterMobile() {
			this.isShowMobileFilter = !this.isShowMobileFilter;
            this.$root.$emit('rootSetContentScrollable', this.isShowMobileFilter);
		},
		removeTag(data) {
			const tagIndex = this.tags.findIndex(x => x.value === data.item.value);
			this.tags.splice(tagIndex, 1);
			this.$refs.filterSearch.updateFilterSelection(this.tags);
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.modal-store-list {
    overflow: hidden;

    .btn-close {
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 1;
        background: rgba(0, 0, 0, 0.75);
        color: #fff;
        opacity: 1;
    }

    .modal-body {
        position: fixed;
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: 10px;
        min-width: 340px;
        padding: 0;
        background: #f0f0f0;
    }

    .white-box, .grey-box {
        padding: 25px;

        @media only screen and (max-width: $screenExtraSmall) {
            padding: 15px 0;
        }
    }

    .white-box {
        background: #fff;
    }

    .filter {
        .loading {
            height: 300px;
        }
    }

    .content-mobile-filter {
        position: static;
        margin-bottom: 8px;
    }

    .delivery {
        border: 1px dotted #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        height: 300px;
        @include borderRadius(5px);

        .wrapper {
            position: relative;
            max-width: 500px;
            width: 100%;
            padding: 25px 25px 20px;
            border: 1px solid #ccc;
            border-top: none;
            @include boxShadow(0 0 10px rgba(100, 100, 100, 0.1));
            
            .decoration {
                @extend .deliveryDecoration;
            }
        }

        .input-field {
            margin-bottom: 12px;

            label {
                color: #000;
                margin-bottom: 5px;

                .icon {
                    margin-right: 5px;
                    opacity: 0.7;
                }
            }
        }

        .action {
            text-align: center;

            .btn {
                padding: 5px 12px;
                font-size: 13px;
            }
        }
    }

    .store-search {
        position: relative;

        .loading {
            height: 300px;
        }
    }

    .area {
        position: relative;
        padding: 15px 15px 10px;
        margin-bottom: 15px;
        background: #fff;
        border: 1px solid #ddd;
        border-top: none;
        font-size: 14px;
        display: flex;
        align-items: center;

        @media only screen and (max-width: $screenExtraSmall) {
            font-size: 13px;
            display: block;
        }

        .decoration {
            @extend .deliveryDecoration;
        }

        label {
            margin-right: 10px;
            font-weight: bold;

            @media only screen and (max-width: $screenExtraSmall) {
                width: 100%;
            }
        }

        span {
            margin-right: 20px;

            @media only screen and (max-width: $screenExtraSmall) {
                display: block;
                width: 100%;
            }

            .icon {
                margin-right: 5px;
            }
        }

        .btn {
            font-size: 13px;

            @media only screen and (max-width: $screenExtraSmall) {
                font-size: 12px;
                margin-top: 5px;
            }
        }
    }

    .store-grid {
        @include gridContentWrapper(0 -5px);

        .store {
            display: block;
            padding: 0 5px 10px;
            width: 33.33333%;

            @media only screen and (max-width: $screenMedium) {
                width: 50%;
            }

            @media only screen and (max-width: $screenExtraSmall) {
                width: 100%;
            }
        }
    }

    .no-data {
        margin: auto;
    }
}

::v-deep .input-field {
    .typeahead {
        input {
            padding: 10px 15px;
            border: none;
            background: #eee;
        }
    }
}
</style>