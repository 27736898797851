<template>
    <div class="box" :class="{ 'sponsor': store.isSponsor }">
        <div class="picture">
            <div class="sponsor-overlay" v-if="store.isSponsor">โฆษณา</div>

            <div class="wrapper" v-if="store.pictureMobile">
                <img :src="store.pictureMobile" :alt="store.overlay.name" :title="store.overlay.name" @error="helper.setNoImg($event, 'store')" @click="purchaseProduct()" class="d-block d-sm-none" />

                <img :src="store.picture" :alt="store.overlay.name" :title="store.overlay.name" @error="helper.setNoImg($event, 'store')" @click="purchaseProduct()" class="d-none d-sm-block" />
            </div>
            <div class="wrapper" v-else>
                <img :src="store.picture" :alt="store.overlay.name" :title="store.overlay.name" @error="helper.setNoImg($event, 'store')" @click="purchaseProduct()" />
            </div>
            <div class="share-link">
                <product-share-link :link="getShareLink()"></product-share-link>
            </div>
            <div class="network-icon" v-if="store.networkData">
                <img :src="store.networkData.icon" :title="store.networkData.title" :alt="store.networkData.title" class="logo" />
            </div>
            <div class="promotion">
                <div class="item" v-if="store.overlay.discount">ลด<span>{{ store.overlay.discount }}</span></div>
                <div class="item" v-if="store.overlay.promotion">{{ store.overlay.promotion }}</div>
            </div>
        </div>

        <div class="detail">
            <section>
                <h2>
                    <router-link
                        :to="routerPath.STORE + '/' + store.param"
                        :title="store.name"
                        target="_blank">
                        {{ store.name }}
                    </router-link>
                </h2>

                <address>
                    <font-awesome-icon :icon="['fas', 'map-marker-alt']" class="icon" />
                    <span>{{ store.shortAddress }}</span>
                </address>

                <div class="benefit">
                    <div class="item discount" v-if="store.overlay.discount">ลด {{ store.overlay.discount }}</div>
                    <div class="item discount" v-if="store.overlay.promotion">{{ store.overlay.promotion }}</div>
                    <div class="item discount" v-if="store.overlay.hasTurnTire">โปรเทิร์นยาง</div>
                    <div class="item discount" v-if="store.overlay.canInstallment">ผ่อน 0%</div>
                    <div class="item free" v-if="store.overlay.hasFreeGift">มีของแถม</div>
                    <div class="item free" v-for="(item, index) in store.overlay.services" :key="index">
                        ฟรี {{ item }}
                    </div>
                    <div class="item free" v-if="store.overlay.isAllowElecTax">ออก e-receipt ได้</div>
                    <div class="item outline" v-if="store.overlay.isPrepaid">มัดจำได้</div>
                    <div class="item outline" v-if="store.overlay.serviceDate">{{ store.overlay.serviceDate }}</div>
                    <div class="item outline" v-if="store.overlay.deliveryPeriod">{{ store.overlay.deliveryPeriod }}</div>
                </div>
            </section>

            <section>
                <div class="product-year">
                    <span>ปีผลิต <i>{{ store.year }}</i></span>
                </div>

                <div class="info">
                    <div class="item">
                        <label>ราคาเส้นละ</label>
                        <div v-if="!isDelivery">
                            <span :class="{ 'cut': store.discountPrice }">{{ store.price }}</span>
                            <span v-if="store.discountPrice">{{ store.discountPrice }}</span>
                        </div>
                        <div v-if="isDelivery">
                            <span :class="{ 'cut': store.discountPriceDelivery }">{{ store.priceDelivery }}</span>
                            <span v-if="store.discountPriceDelivery">{{ store.discountPriceDelivery }}</span>
                        </div>
                    </div>
                    <div class="item" v-if="isDelivery">
                        <label>ค่าจัดส่งเส้นละ</label>
                        <span class="free" v-if="store.overlay.hasFreeDelivery">ส่งฟรี!</span>
                        <span class="delivery" v-else>{{ store.deliveryFee }}</span>
                    </div>
                </div>
                
                <!-- <div class="rating mt-1">
                    <b-icon icon="star-fill"
                        class="icon"
                        :class="{ 'active': (index + 1) <= store.starRating }"
                        v-for="(star, index) in 5"
                        :key="index">
                    </b-icon>
                    <div class="users" v-if="store.totalUserRating">({{ store.totalUserRating }})</div>

                    <store-share-product-link :link="getShareLink()" class="share-link" v-if="type === constants.ITEM_BOX_TYPE.PURCHASE"></store-share-product-link>
                </div> -->
                
                <div class="button-operation text-center mt-2">
                    <button
                        name="btnPurchase"
                        @click="purchaseProduct()"
                        class="btn btn-warning btn-action">
                        <b-icon icon="cart-plus" class="icon"></b-icon> ใส่ตะกร้า
                    </button>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Helper from '@/services/helper';
import RouterPath from '@/router/path';
import Constants from '@/variables/constants';
import OrderService from '@/services/orderService';
import ProductShareLink from '@/components/product/ShareLink';

export default {
    components: {
        ProductShareLink
    },
    data() {
		return {
            constants: Constants,
            helper: Helper,
            routerPath: RouterPath
		};
	},
	props: {
        store: {
            type: Object,
            required: true
        },
        product: {
            type: Object,
            required: true
        },
        isDelivery: {
            type: Boolean,
            required: true
        },
        trackingReferrer: {
            type: String,
            required: false
        }
	},
	methods: {
        async purchaseProduct() {
            const vueObject = {
                modalLoader: this.$modalLoader,
                router: this.$router,
                gtag: this.$gtag,
                gtm: this.$gtm,
                root: this.$root,
                swal: this.$swal
            };
            const param = {
                sellerProductId: this.store.sellerProductId,
                storeId: Helper.splitDotParam(this.store.param),
                orderType: this.isDelivery ? Constants.ORDER_TYPE.DELIVERY : Constants.ORDER_TYPE.SERVICE,
                addressDistrictId: this.store.addressDistrictId,
                referer: this.trackingReferrer
            };
            const gtmData = {
                product: this.product,
                type: this.isDelivery ? 'delivery' : 'install',
                sellerProductId: this.store.sellerProductId,
                storeId: Helper.splitDotParam(this.store.param),
                price: this.isDelivery ? this.store.priceDelivery : this.store.price
            };

            OrderService.toggleAddCartEvent(vueObject, param, gtmData);
        },
        getShareLink() {
            return Helper.replaceRouteParam(RouterPath.STORE_DETAIL, ':id', this.store.param) + '/' + this.store.sellerProductId;
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.box {
    background: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;

    &.sponsor {
        border: 2px solid #ababab;
    }

    @media only screen and (max-width: $screenExtraSmall) {
        flex-direction: row;
    }

    a {
        color: #000;
        display: block;
    }

    .picture {
        position: relative;
        overflow: hidden;
        padding-top: 74%;
        background: #eee;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            opacity: 0.9;
        }

        @media only screen and (max-width: $screenExtraSmall) {
            width: 135px;
            padding-top: 0;
        }

        .sponsor-overlay {
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            padding: 4px 8px;
            font-size: 11px;
            color: #fff;
            background: #ababab;
        }

        .wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .share-link {
            position: absolute;
            top: 5px;
            right: 5px;
            z-index: 1;
            background: rgba(0, 0, 0, 0.5);
            padding: 3px 7px;
            font-size: 13px;
            @include borderRadius(5px);
        }

        .network-icon {
            position: absolute;
            top: 0;
            left: 5px;

            .logo {
                width: 50px;
                height: 50px;
            }
        }

        .promotion {
            position: absolute;
            bottom: 5px;
            right: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .item {
                background: #ffff00;
                color: #cc0000;
                font-size: 13px;
                line-height: 13px;
                padding: 3px 5px;
                text-align: center;
                margin-top: 3px;

                @media only screen and (max-width: $screenExtraSmall) {
                    font-size: 11px;
                    line-height: 11px;
                }

                span {
                    display: block;
                }
            }
        }
    }

    .detail {
        padding: 10px;
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h2 {
            font-size: 18px;
            font-weight: bold;
            margin: 0;
            
            a {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        address {
            font-size: 13px;
            color: #777;
            margin: 0;

            .icon {
                margin-right: 5px;
                font-size: 10px;
            }
        }

        .benefit {
            display: flex;
            flex-wrap: wrap;

            .item {
                font-size: 11px;
                padding: 2px 6px;
                margin: 2px;
                color: #000;
                background: #ccc;

                &.discount {
                    color: #cc0000;
                    background: #ffff00;
                }

                &.free {
                    color: #19614e;
                    background: #d8f3ec;
                }

                &.outline {
                    color: #cc0000;
                    background: none;
                    border: 1px solid #cc0000;
                }
            }
        }
        
        .product-year {
            margin-top: 10px; 
            font-size: 13px;
            color: #999;

            i {
                color: #333;
                font-family: Verdana;
                font-weight: bold;
            }
        }

        .info {
            .item {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                label {
                    margin: 0;
                    font-size: 13px;
                    line-height: 13px;
                }

                span {
                    font-size: 20px;
                    font-weight: bold;
                    font-family: Verdana;
                    line-height: 20px;
                    color: #cc0000;

                    &:before {
                        content: "฿";
                        font-size: 13px;
                        margin-right: 3px;
                    }

                    &.cut {
                        font-size: 13px;
                        font-weight: normal;
                        color: #aaa;
                        margin-right: 3px;
                        text-decoration: line-through;
                    }

                    &.delivery {
                        font-size: 16px;
                        font-weight: normal;
                    }

                    &.free {
                        font-size: 16px;
                        font-weight: normal;
                        color: #2cb18d;

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }

        .desc {
            font-size: 13px;
            line-height: 13px;
            color: #333;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;

            @media only screen and (max-width: $screenExtraSmall) {
                font-size: 12px;
                line-height: 12px;
            }
        }

        .rating {
            position: relative;
            display: flex;
            align-items: center;

            .icon {
                color: #bbb;
                margin-right: 3px;

                &.active {
                    color: #ffaa00;
                }
            }

            .users {
                font-size: 15px;
            }

            .share-link {
                position: absolute;
                right: 5px;
            }
        }

        .button-operation {
            .btn {
                display: block;
                width: 100%;
                font-size: 13px;
                font-weight: bold;
                padding: 7px 10px;

                & + .btn {
                    margin-top: 5px;
                    margin-left: 0;
                }
            }

            .btn-action {
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                    margin-right: 10px;
                    margin-bottom: 2px;
                    font-size: 15px;
                    line-height: 15px;
                }
            }
        }
    }
}

::v-deep .share {
    .icon {
        color: #fff;
    }
}
</style>
