<template>
    <div class="share">
        <b-icon icon="share" class="icon" @click="toggleShareLink()"></b-icon>
        <div class="popover-share" v-if="isShowShareLink">
            <input class="type" :value="fullUrlLink" readonly onClick="this.setSelectionRange(0, this.value.length)" @click="copyLink()" />
            <b-icon icon="clipboard" class="copy" @click="copyLink()"></b-icon>
        </div>
        <Transition>
            <div class="alert" v-if="isShowCompleteCopied">คัดลอกแล้ว</div>
        </Transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isShowShareLink: false,
            isShowCompleteCopied: false,
            fullUrlLink: ''
        };
    },
    props: {
        link: {
            type: String,
            required: true
        }
    },
    mounted() {
        this.fullUrlLink = this.getLink();
        document.addEventListener('click', this.handleClickOutside);
    },
    destroyed() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        getLink() {
            return location.protocol + '//' + location.hostname + this.link;
        },
        toggleShareLink() {
            this.isShowShareLink = !this.isShowShareLink;
        },
        copyLink() {
            if (window.isSecureContext && navigator.clipboard) {
                navigator.clipboard.writeText(this.fullUrlLink);
            }
            
            this.isShowCompleteCopied = true;

            setTimeout(() => {
                this.isShowCompleteCopied = false;
            }, 1000);
        },
        handleClickOutside(e) {
            if (this.$el.contains(e.target)) {
                return;
            } else if (this.isShowShareLink) {
                this.isShowShareLink = false;   
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.share {
    position: relative;

    .icon {
        cursor: pointer;
        color: #999;
        outline: none;
        
        &:hover {
            opacity: 0.5;
        }
    }

    .popover-share {
        position: absolute;
        z-index: 1;
        padding: 8px 12px;
        right: -10px;
        border: 1px solid #ddd;
        background: #fff;
        display: flex;
        align-items: center;
        @include boxShadow(0 0 20px rgba(0, 0, 0, 0.2));

        input {
            width: 150px;
            font-size: 12px;
            background: #f2f2f2;
            border: 1px solid #ccc;
            padding: 2px 5px;
            outline: none;
            cursor: pointer;
        }

        .copy {
            margin-left: 10px;
            font-size: 20px;
            color: #999;
            cursor: pointer;

            &:hover {
                opacity: 0.5;
            }
        }
    }

    .alert {
        position: absolute;
        top: -7px;
        right: -10px;
        background: rgba(0, 0, 0, 0.6);
        color: #fff;
        font-size: 12px;
        padding: 4px 8px;
        white-space: nowrap;
    }
}

.v-enter-active,
.v-leave-active {
    transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>