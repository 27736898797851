export default {
    data() {
        return {
            isOpenStoreList: false,
            isDelivery: false,
            selectedProduct: null,
        };
    },
    methods: {
        openStoreList(data) {
            this.isDelivery = data.isDelivery;
			this.selectedProduct = data.product;
			this.isOpenStoreList = true;

            this.$root.$emit('rootToggleFacebookChat', false);
		},
		closeStoreList() {
            this.isOpenStoreList = false;
        },
    }
}